html, body, #root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

/********/

/*@font-face {*/
/*  font-family: 'Satella';*/
/*  src: local('Satella'), url(Fonts/Satella/SatellaRegular.ttf) format('truetype');*/
/*}*/

/*!*@font-face {*!*/
/*!*  font-family: 'Space Frigate';*!*/
/*!*  src: local('SpaceFrigate'), url(Fonts/SpaceFrigate/SpaceFrigate.ttf) format('truetype');*!*/
/*!*}*!*/

/*@font-face {*/
/*  font-family: 'Regular Earth';*/
/*  src: local('RegularEarth'), url(Fonts/RegularEarth/RegularEarth.ttf) format('truetype');*/
/*}*/

/*@font-face {*/
/*  font-family: 'Final Frontier';*/
/*  src: local('FinalFrontier'), url(Fonts/FinalFrontier/FinalFrontier.ttf) format('truetype');*/
/*}*/

@font-face {
  font-family: 'Control Freak';
  src: local('ControlFreak'), url(Resources/Fonts/ControlFreak/ControlFreak.ttf) format('truetype');
}

@font-face {
  font-family: 'Comic Helvetic';
  font-weight: 400;
  src: local('ComicHelvetic'), url(Resources/Fonts/ComicHelvetic/ComicHelvetic_Medium.otf) format('truetype');
}
@font-face {
  font-family: 'Comic Helvetic';
  font-weight: 600;
  src: local('ComicHelvetic'), url(Resources/Fonts/ComicHelvetic/ComicHelvetic_Heavy.otf) format('truetype');
}
@font-face {
  font-family: 'Comic Helvetic';
  font-weight: 200;
  src: local('ComicHelvetic'), url(Resources/Fonts/ComicHelvetic/ComicHelvetic_Light.otf) format('truetype');
}

/******************/



















