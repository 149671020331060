.Home.Desktop {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #111111;
    overflow-y: scroll;
    overflow-x: hidden;
}

/********/

.Home.Desktop .Header {
    width: 100%;
    height: 80px;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: black;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
}

.Home.Desktop .Header .HeaderImage {
    width: auto;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.Home.Desktop .Header .HeaderImage img {
    width: auto;
    height: 70%;
    min-height: 70%;
    object-fit: contain;
}

.Home.Desktop .Header .HeaderRight {
    width: auto;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 16px;
}

.Home.Desktop .Header .HeaderRight .SocialIcon {
    width: 24px;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 14px;
    opacity: 1;
}
.Home.Desktop .Header .HeaderRight .SocialIcon:hover {
    opacity: 0.8;
}

.Home.Desktop .Header .HeaderRight .SocialIcon img {
    width: 24px;
    height: 24px;
    min-height: 24px;
    object-fit: contain;
}

/********/

.Home.Desktop .MainBody {
    width: 100%;
    height: max-content;
    min-height: max-content;
}

.Home.Desktop .MainBody .TopSection {
    width: 88%;
    height: max-content;
    min-height: max-content;
    margin-top: 60px;
    padding: 0 6%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Home.Desktop .MainBody .TopSection .GIFContainer {
    width: 40vw;
    height: 40vw;
    min-height: 40vw;
}

.Home.Desktop .MainBody .TopSection .GIFContainer img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    border-radius: 10px;
}

.Home.Desktop .MainBody .TopSection .GIFContainer .Disclaimer {
    width: 0;
    height: 0;
    max-height: 0;
    overflow: hidden;
}

.Home.Desktop .MainBody .TopSection .TSText {
    width: calc(94% - 40vw);
    height: max-content;
    min-height: max-content;
    font-family: "Comic Helvetic", sans-serif;
    color: white;
}

.Home.Desktop .MainBody .TopSection .TSText .TSTitle {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-bottom: 3vw;
    font-size: 3.6vw;
    font-weight: 400;
}

.Home.Desktop .MainBody .TopSection .TSText .TSParagraph {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 2vw;
    font-size: 1.8vw;
    font-weight: 200;
}

/********/

.Home.Desktop .MainBody .Roadmap {
    width: 84%;
    height: max-content;
    min-height: max-content;
    margin-top: 80px;
    padding: 0 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Home.Desktop .MainBody .Roadmap .SectionHeading {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: 6vw;
    text-decoration: underline;
    font-weight: 600;
    color: white;
}

.Home.Desktop .MainBody .Roadmap .SectionSubheading {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: 3vw;
    font-weight: 200;
    text-align: center;
    color: gold;
}

/********/

.Home.Desktop .MainBody .Details {
    width: 84%;
    height: max-content;
    min-height: max-content;
    margin-top: 80px;
    padding: 0 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Home.Desktop .MainBody .Details .SectionHeading {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: 6vw;
    text-decoration: underline;
    font-weight: 600;
    color: white;
}

.Home.Desktop .MainBody .Details .SectionSubheading {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: 3vw;
    font-weight: 200;
    text-align: center;
    color: gold;
}

.Home.Desktop .MainBody .Details .DTRow {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Comic Helvetic", sans-serif;
    margin-top: 30px;
}

.Home.Desktop .MainBody .Details .DTRow .Percentage {
    width: 18vw;
    height: 18vw;
    min-height: 18vw;
    /*border-radius: 50%;*/
    /*border: 2px solid gold;*/
    /*background-color: rgba(192, 192, 192, 0.25);*/
    /*box-shadow: inset 0 0 4vw 0 silver;*/
    /*font-size: 4vw;*/
    /*font-weight: 200;*/
    /*color: gold;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/*.Home.Desktop .MainBody .Details .DTRow.Current .Percentage {*/
/*    width: 14vw;*/
/*    height: 14vw;*/
/*    min-height: 14vw;*/
/*    !*border-radius: 50%;*!*/
/*    !*border: 2px solid gold;*!*/
/*    !*background-color: rgba(255, 215, 0, 0.1);*!*/
/*    !*box-shadow: inset 0 0 4vw 0 rgba(255, 215, 0, 0.5);*!*/
/*    !*font-size: 4vw;*!*/
/*    !*font-weight: 600;*!*/
/*    !*color: white;*!*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*}*/

.Home.Desktop .MainBody .Details .DTRow .Percentage .PInner {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .MainBody .Details .DTRow .DTRContent {
    width: calc(98% - 18vw);
    height: max-content;
    min-height: max-content;
}

.Home.Desktop .MainBody .Details .DTRow .DTRContent .DTRTitle {
    width: 100%;
    height: max-content;
    min-height: max-content;
    padding-top: 2vw;
    font-size: 3.2vw;
    color: gold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.Home.Desktop .MainBody .Details .DTRow .DTRContent .DTRDesc {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 2vw;
    font-size: 2.2vw;
    font-weight: 200;
    color: silver;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

/********/

.Home.Desktop .MainBody .Team {
    width: 90%;
    height: max-content;
    min-height: max-content;
    margin-top: 80px;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Home.Desktop .MainBody .Team .SectionHeading {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: 6vw;
    text-decoration: underline;
    font-weight: 600;
    color: white;
}

.Home.Desktop .MainBody .Team .TeamRow {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
}

.Home.Desktop .MainBody .Team .TeamRow .TeamMember {
    width: min(26vw, 300px);
    height: max-content;
    min-height: max-content;
    margin-left: min(2vw, 30px);
    margin-right: min(2vw, 30px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Home.Desktop .MainBody .Team .TeamRow .TeamMember .PFPContainer {
    width: min(26vw, 300px);
    height: min(26vw, 300px);
    min-height: min(26vw, 300px);
}

.Home.Desktop .MainBody .Team .TeamRow .TeamMember .PFPContainer img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.25);
}

.Home.Desktop .MainBody .Team .TeamRow .TeamMember .TMName {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 16px;
    text-align: center;
    font-size: min(2.4vw, 28px);
    line-height: min(2.8vw, 32px);
    color: gold;
}

.Home.Desktop .MainBody .Team .TeamRow .TeamMember .TMTitle {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 10px;
    text-align: center;
    font-size: min(1.9vw, 22px);
    font-weight: 200;
    color: silver;
}

/********/

.Home.Desktop .MainBody .FAQ {
    width: 84%;
    height: max-content;
    min-height: max-content;
    margin-top: 80px;
    padding: 0 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Home.Desktop .MainBody .FAQ .SectionHeading {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: 6vw;
    text-decoration: underline;
    font-weight: 600;
    color: white;
}

.Home.Desktop .MainBody .FAQ .FAQItem {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Comic Helvetic", sans-serif;
}
.Home.Desktop .MainBody .FAQ .FAQItem:nth-child(1) {
    margin-top: 0;
}

.Home.Desktop .MainBody .FAQ .FAQItem .Question {
    width: 100%;
    height: max-content;
    min-height: max-content;
    font-size: 3vw;
    line-height: calc(3vw + 4px);
    font-weight: 200;
    color: gold;
}

.Home.Desktop .MainBody .FAQ .FAQItem .Answer {
    width: 94%;
    height: max-content;
    min-height: max-content;
    margin-top: 10px;
    padding: 0 3%;
    font-size: 1.8vw;
    line-height: calc(1.8vw + 2px);
    font-weight: 200;
    color: silver;
}

/********/

.Home.Desktop .Footer {
    width: 100%;
    height: max-content;
    min-height: max-content;
    padding: 40px 0 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: black;
}

.Home.Desktop .Footer .LogoContainer {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .Footer .LogoContainer img {
    width: min(20vw, 200px);
    height: min(20vw, 200px);
    min-height: min(15vw, 150px);
    object-fit: contain;
}

.Home.Desktop .Footer .PoweredBy {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: min(4vw, 40px);
    font-weight: 200;
    color: gold;
}

.Home.Desktop .Footer .TeraChain {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Control Freak", sans-serif;
}

.Home.Desktop .Footer .TeraChain a {
    width: auto;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-decoration: none;
}

.Home.Desktop .Footer .TeraChain a .Name {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .Footer .TeraChain a .Name .NameInner {
    width: auto;
    height: max-content;
    min-height: max-content;
    font-size: min(6vw, 60px);
    color: transparent;
    background: linear-gradient(0deg, #BA1C9B 20%, #263EF2 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Home.Desktop .Footer .TeraChain a .Subtitle {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .Footer .TeraChain a .Subtitle .SubtitleInner {
    width: auto;
    height: max-content;
    min-height: max-content;
    font-size: min(3vw, 30px);
    color: transparent;
    background: -webkit-linear-gradient(90deg, #DDDDDD 10%, #888888 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Home.Desktop .Footer .Copyright {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: min(1.6vw, 16px);
    font-weight: 200;
    color: silver;
}

.Home.Desktop .Footer .Socials {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .Footer .Socials .SocialIcon {
    width: 32px;
    height: 32px;
    min-height: 32px;
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
}
.Home.Desktop .Footer .Socials .SocialIcon:hover {
    opacity: 0.8;
}

.Home.Desktop .Footer .Socials .SocialIcon img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: contain;
}

/******************/



















