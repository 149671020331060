.Home.Mobile {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #111111;
    overflow-y: scroll;
}

/********/

.Home.Mobile .Header {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
}

.Home.Mobile .Header .HeaderImage {
    width: 100%;
    height: max-content;
    min-height: max-content;
    padding: 10px 0 4px 0;
    background-color: black;
}

.Home.Mobile .Header .HeaderImage img {
    width: 90vw;
    height: auto;
    min-height: auto;
    padding: 0 5vw;
    object-fit: contain;
}

.Home.Mobile .Header .HeaderSocials {
    width: auto;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Mobile .Header .HeaderSocials .HSInner {
    width: auto;
    height: max-content;
    min-height: max-content;
    padding: 4px 6px 0 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.Home.Mobile .Header .HeaderSocials .SocialIcon {
    width: 24px;
    height: 100%;
    min-height: 100%;
    padding: 4px 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
}
.Home.Mobile .Header .HeaderSocials .SocialIcon:hover {
    opacity: 0.8;
}

.Home.Mobile .Header .HeaderSocials .SocialIcon img {
    width: 24px;
    height: 24px;
    min-height: 24px;
    object-fit: contain;
}

/********/

.Home.Mobile .MainBody {
    width: 100%;
    height: max-content;
    min-height: max-content;
}

.Home.Mobile .MainBody .TopSection {
    width: 84vw;
    height: max-content;
    min-height: max-content;
    margin-top: 20px;
    padding: 0 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Home.Mobile .MainBody .TopSection .GIFContainer {
    width: 84vw;
    height: 84vw;
    min-height: 84vw;
}

.Home.Mobile .MainBody .TopSection .GIFContainer img {
    width: 84vw;
    height: 84vw;
    min-height: 84vw;
    border-radius: 10px;
}

.Home.Mobile .MainBody .TopSection .GIFContainer .Disclaimer {
    width: 0;
    height: 0;
    max-height: 0;
    overflow: hidden;
}

.Home.Mobile .MainBody .TopSection .TSText {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: min(8vw, 32px);
    font-family: "Comic Helvetic", sans-serif;
    color: white;
}

.Home.Mobile .MainBody .TopSection .TSText .TSTitle {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-bottom: 8vw;
    font-size: min(8vw, 32px);
    font-weight: 400;
    text-align: center;
}

.Home.Mobile .MainBody .TopSection .TSText .TSParagraph {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 4vw;
    font-size: min(4vw, 16px);
    font-weight: 200;
    text-align: center;
}

/********/

.Home.Mobile .MainBody .Roadmap {
    width: 90%;
    height: max-content;
    min-height: max-content;
    margin-top: 60px;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Home.Mobile .MainBody .Roadmap .SectionHeading {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: min(12vw, 48px);
    text-decoration: underline;
    font-weight: 600;
    color: white;
}

.Home.Mobile .MainBody .Roadmap .RMRow {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Comic Helvetic", sans-serif;
    margin-top: 24px;
}

.Home.Mobile .MainBody .Roadmap .RMRow .Percentage {
    width: 16vw;
    height: 16vw;
    min-height: 16vw;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50%;
    border: 2px solid gold;
    background-color: rgba(192, 192, 192, 0.25);
    box-shadow: inset 0 0 4vw 0 silver;
    font-size: 5vw;
    font-weight: 200;
    color: gold;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Home.Mobile .MainBody .Roadmap .RMRow.Current .Percentage {
    width: 16vw;
    height: 16vw;
    min-height: 16vw;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50%;
    border: 2px solid gold;
    background-color: rgba(255, 215, 0, 0.25);
    box-shadow: inset 0 0 4vw 0 gold;
    font-size: 5vw;
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Home.Mobile .MainBody .Roadmap .RMRow .Percentage .PInner {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Mobile .MainBody .Roadmap .RMRow .RMRContent {
    width: calc(96% - 16vw - 4px);
    height: max-content;
    min-height: max-content;
}

.Home.Mobile .MainBody .Roadmap .RMRow .RMRContent .RMRTitle {
    width: 100%;
    height: max-content;
    min-height: max-content;
    padding-top: 2vw;
    font-size: min(6vw, 24px);
    color: gold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.Home.Mobile .MainBody .Roadmap .RMRow .RMRContent .RMRDesc {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 2vw;
    font-size: min(4vw, 16px);
    font-weight: 200;
    color: silver;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

/********/

.Home.Mobile .MainBody .Team {
    width: 80vw;
    height: max-content;
    min-height: max-content;
    margin-top: 60px;
    padding: 0 10vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Home.Mobile .MainBody .Team .SectionHeading {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: min(12vw, 48px);
    text-decoration: underline;
    font-weight: 600;
    color: white;
}

.Home.Mobile .MainBody .Team .TeamRow {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Comic Helvetic", sans-serif;
}

.Home.Mobile .MainBody .Team .TeamRow .TeamMember {
    width: 80vw;
    height: max-content;
    min-height: max-content;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.Home.Mobile .MainBody .Team .TeamRow .TeamMember:nth-child(1) {
    margin-top: 0;
}

.Home.Mobile .MainBody .Team .TeamRow .TeamMember .PFPContainer {
    width: 80vw;
    height: 80vw;
    min-height: 80vw;
}

.Home.Mobile .MainBody .Team .TeamRow .TeamMember .PFPContainer img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    border-radius: 10px;
    box-shadow: 0 0 4vw 0 rgba(255, 255, 255, 0.25);
}

.Home.Mobile .MainBody .Team .TeamRow .TeamMember .TMName {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 16px;
    text-align: center;
    font-size: min(6vw, 24px);
    line-height: calc(min(6vw, 24px) + 4px);
    color: gold;
}

.Home.Mobile .MainBody .Team .TeamRow .TeamMember .TMTitle {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 4px;
    text-align: center;
    font-size: min(4vw, 16px);
    line-height: calc(min(4vw, 16px) + 2px);
    font-weight: 200;
    color: silver;
}

/********/

.Home.Mobile .MainBody .FAQ {
    width: 90%;
    height: max-content;
    min-height: max-content;
    margin-top: 60px;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Home.Mobile .MainBody .FAQ .SectionHeading {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: min(12vw, 48px);
    text-decoration: underline;
    font-weight: 600;
    color: white;
}

.Home.Mobile .MainBody .FAQ .FAQItem {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Comic Helvetic", sans-serif;
    text-align: center;
}
.Home.Mobile .MainBody .FAQ .FAQItem:nth-child(1) {
    margin-top: 0;
}

.Home.Mobile .MainBody .FAQ .FAQItem .Question {
    width: 100%;
    height: max-content;
    min-height: max-content;
    font-size: min(6vw, 24px);
    line-height: calc(min(6vw, 24px) + 4px);
    font-weight: 200;
    color: gold;
}

.Home.Mobile .MainBody .FAQ .FAQItem .Answer {
    width: 94%;
    height: max-content;
    min-height: max-content;
    margin-top: 10px;
    padding: 0 3%;
    font-size: min(4vw, 16px);
    line-height: calc(min(4vw, 16px) + 2px);
    font-weight: 200;
    color: silver;
}

/********/

.Home.Mobile .Footer {
    width: 100%;
    height: max-content;
    min-height: max-content;
    padding: 10px 0 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: black;
}

.Home.Mobile .Footer .LogoContainer {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Mobile .Footer .LogoContainer img {
    width: min(40vw, 160px);
    height: min(40vw, 160px);
    min-height: min(40vw, 160px);
    object-fit: contain;
}

.Home.Mobile .Footer .PoweredBy {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: min(8vw, 32px);
    font-weight: 200;
    color: gold;
}

.Home.Mobile .Footer .TeraChain {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Control Freak", sans-serif;
}

.Home.Mobile .Footer .TeraChain a {
    width: auto;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-decoration: none;
}

.Home.Mobile .Footer .TeraChain a .Name {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Mobile .Footer .TeraChain a .Name .NameInner {
    width: auto;
    height: max-content;
    min-height: max-content;
    font-size: min(12vw, 48px);
    color: transparent;
    background: linear-gradient(0deg, #BA1C9B 20%, #263EF2 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Home.Mobile .Footer .TeraChain a .Subtitle {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Mobile .Footer .TeraChain a .Subtitle .SubtitleInner {
    width: auto;
    height: max-content;
    min-height: max-content;
    font-size: min(6vw, 24px);
    color: transparent;
    background: -webkit-linear-gradient(90deg, #DDDDDD 10%, #888888 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Home.Mobile .Footer .Copyright {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Comic Helvetic", sans-serif;
    font-size: min(3vw, 12px);
    font-weight: 200;
    color: silver;
}

.Home.Mobile .Footer .Socials {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Mobile .Footer .Socials .SocialIcon {
    width: 28px;
    height: 28px;
    min-height: 28px;
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
}
.Home.Mobile .Footer .Socials .SocialIcon:hover {
    opacity: 0.8;
}

.Home.Mobile .Footer .Socials .SocialIcon img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: contain;
}

/******************/



















